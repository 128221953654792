import React from 'react';
import './footer.css';

const footer = () => {
  return (
    <div className='footer'>
      <div className='footerCopyright'>
        &copy; Copyright {1900 + new Date().getYear()} AppliedAIConsulting - All Rights Reserved
      </div>
    </div>
  );
};

export default footer;
