import React from 'react';

const SolutionIcon = (props) => {
  return (
    <>
      <svg
        id='Profile'
        width='49.2'
        height='22.419'
        viewBox='0 0 49.2 22.419'
      >
        <defs>
          <clipPath id='clip-path'>
            <path
              id='Vector'
              className='cls-1'
              d='M44.8,22.419a4.378,4.378,0,0,0,4.092-5.954,26.152,26.152,0,0,0-48.583,0A4.377,4.377,0,0,0,4.4,22.419Z'
              transform='translate(0 0)'
            />
          </clipPath>
        </defs>
        <g
          id='Mask_Group_1'
          data-name='Mask Group 1'
          className='cls-2'
          transform='translate(0 0)'
        >
          <g
            id='infrastructure'
            transform='translate(13.39 0)'
          >
            <path
              id='Path_6'
              data-name='Path 6'
              style={{ fill: props.color }}
              d='M21.555,6.634a4.483,4.483,0,0,0-2.493-1.443A6.727,6.727,0,0,0,6.92,3.33a2.615,2.615,0,0,0-3.236,1.9A4.484,4.484,0,0,0,1.271,12.44l.576-.476A3.737,3.737,0,0,1,4.729,5.85c.113,0,.228.005.34.015l.067-.744C5,5.109,4.865,5.1,4.729,5.1q-.111,0-.22.005a1.867,1.867,0,0,1,2.028-1.1c-.094.192-.18.388-.256.589l.7.264a5.979,5.979,0,0,1,11.574,2.11H19.3A6.747,6.747,0,0,0,19.231,6a3.755,3.755,0,0,1,2.686,3.584,3.712,3.712,0,0,1-.679,2.149l.611.43a4.484,4.484,0,0,0-.294-5.531Z'
              transform='translate(-0.245 -0.245)'
            />
            <ellipse
              id='Ellipse_1'
              data-name='Ellipse 1'
              style={{ fill: props.color }}
              cx='0.374'
              cy='0.374'
              rx='0.374'
              ry='0.374'
              transform='translate(2.989 15.693)'
            />
            <ellipse
              id='Ellipse_2'
              data-name='Ellipse 2'
              style={{ fill: props.color }}
              cx='0.374'
              cy='0.374'
              rx='0.374'
              ry='0.374'
              transform='translate(4.11 15.693)'
            />
            <ellipse
              id='Ellipse_3'
              data-name='Ellipse 3'
              style={{ fill: props.color }}
              cx='0.374'
              cy='0.374'
              rx='0.374'
              ry='0.374'
              transform='translate(5.231 15.693)'
            />
            <rect
              id='Rectangle_85'
              data-name='Rectangle 85'
              style={{ fill: props.color }}
              width='2.242'
              height='0.747'
              transform='translate(6.726 15.693)'
            />
            <ellipse
              id='Ellipse_4'
              data-name='Ellipse 4'
              style={{ fill: props.color }}
              cx='0.374'
              cy='0.374'
              rx='0.374'
              ry='0.374'
              transform='translate(2.989 12.704)'
            />
            <ellipse
              id='Ellipse_5'
              data-name='Ellipse 5'
              style={{ fill: props.color }}
              cx='0.374'
              cy='0.374'
              rx='0.374'
              ry='0.374'
              transform='translate(4.11 12.704)'
            />
            <ellipse
              id='Ellipse_6'
              data-name='Ellipse 6'
              style={{ fill: props.color }}
              cx='0.374'
              cy='0.374'
              rx='0.374'
              ry='0.374'
              transform='translate(5.231 12.704)'
            />
            <rect
              id='Rectangle_86'
              data-name='Rectangle 86'
              style={{ fill: props.color }}
              width='2.242'
              height='0.747'
              transform='translate(6.726 12.704)'
            />
            <path
              id='Path_7'
              data-name='Path 7'
              style={{ fill: props.color }}
              d='M7.958,2.946h-5.6A1.5,1.5,0,0,0,.859,4.44v.747a1.491,1.491,0,0,0,.508,1.121A1.491,1.491,0,0,0,.859,7.429v.747A1.491,1.491,0,0,0,1.367,9.3a1.491,1.491,0,0,0-.508,1.121v.747A1.5,1.5,0,0,0,2.354,12.66h5.6a1.5,1.5,0,0,0,1.495-1.495v-.747A1.491,1.491,0,0,0,8.945,9.3a1.491,1.491,0,0,0,.508-1.121V7.429a1.491,1.491,0,0,0-.508-1.121,1.491,1.491,0,0,0,.508-1.121V4.44A1.5,1.5,0,0,0,7.958,2.946Zm.747,7.473v.747a.748.748,0,0,1-.747.747h-5.6a.748.748,0,0,1-.747-.747v-.747a.748.748,0,0,1,.747-.747h5.6a.748.748,0,0,1,.747.747Zm0-2.989v.747a.748.748,0,0,1-.747.747h-5.6a.748.748,0,0,1-.747-.747V7.429a.748.748,0,0,1,.747-.747h5.6A.748.748,0,0,1,8.706,7.429ZM2.354,5.935a.748.748,0,0,1-.747-.747V4.44a.748.748,0,0,1,.747-.747h5.6a.748.748,0,0,1,.747.747v.747a.748.748,0,0,1-.747.747Z'
              transform='translate(1.009 5.275)'
            />
            <ellipse
              id='Ellipse_7'
              data-name='Ellipse 7'
              style={{ fill: props.color }}
              cx='0.374'
              cy='0.374'
              rx='0.374'
              ry='0.374'
              transform='translate(2.989 9.715)'
            />
            <ellipse
              id='Ellipse_8'
              data-name='Ellipse 8'
              style={{ fill: props.color }}
              cx='0.374'
              cy='0.374'
              rx='0.374'
              ry='0.374'
              transform='translate(4.11 9.715)'
            />
            <ellipse
              id='Ellipse_9'
              data-name='Ellipse 9'
              style={{ fill: props.color }}
              cx='0.374'
              cy='0.374'
              rx='0.374'
              ry='0.374'
              transform='translate(5.231 9.715)'
            />
            <rect
              id='Rectangle_87'
              data-name='Rectangle 87'
              style={{ fill: props.color }}
              width='2.242'
              height='0.747'
              transform='translate(6.726 9.715)'
            />
            <path
              id='Path_8'
              data-name='Path 8'
              style={{ fill: props.color }}
              d='M9.516,3.559H6.527A1.5,1.5,0,0,0,5.032,5.054V7.3A1.5,1.5,0,0,0,6.527,8.79H9.516A1.5,1.5,0,0,0,11.01,7.3V5.054A1.5,1.5,0,0,0,9.516,3.559Zm.747,1.495v.2a1.485,1.485,0,0,0-.747-.2h-.8a.743.743,0,0,1-.668-.413l-.128-.255c-.013-.027-.028-.053-.043-.079H9.516A.748.748,0,0,1,10.263,5.054Zm0,2.242a.748.748,0,0,1-.747.747H6.527A.748.748,0,0,1,5.779,7.3V5.054a.748.748,0,0,1,.747-.747h.055a.743.743,0,0,1,.668.413l.128.255A1.486,1.486,0,0,0,8.714,5.8h.8a.748.748,0,0,1,.747.747Z'
              transform='translate(9.54 6.529)'
            />
            <path
              id='Path_9'
              data-name='Path 9'
              style={{ fill: props.color }}
              d='M9.516,5.891H6.527A1.5,1.5,0,0,0,5.032,7.386V9.628a1.5,1.5,0,0,0,1.495,1.495H9.516A1.5,1.5,0,0,0,11.01,9.628V7.386A1.5,1.5,0,0,0,9.516,5.891Zm.747,1.495v.2a1.485,1.485,0,0,0-.747-.2h-.8a.743.743,0,0,1-.668-.413l-.128-.255c-.013-.027-.028-.053-.043-.079H9.516A.748.748,0,0,1,10.263,7.386Zm0,2.242a.748.748,0,0,1-.747.747H6.527a.748.748,0,0,1-.747-.747V7.386a.748.748,0,0,1,.747-.747h.055a.743.743,0,0,1,.668.413l.128.255a1.486,1.486,0,0,0,1.337.826h.8a.748.748,0,0,1,.747.747Z'
              transform='translate(9.54 11.296)'
            />
            <path
              id='Path_10'
              data-name='Path 10'
              style={{ fill: props.color }}
              d='M3.8,4.3v.747H4.926v7.1a.374.374,0,0,0,.374.374H6.794v-.747H5.673V5.043H6.794V4.3Z'
              transform='translate(7.031 8.035)'
            />
          </g>
        </g>
      </svg>
    </>
  );
};

export default SolutionIcon;
