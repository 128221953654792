import React, { useState, useEffect } from 'react';
import axios from 'axios';
import PipelineItem from './PipelineItem';
import Loader from '../../components/Loader/BouncingDotsLoader';
import './style.css';
/**
 * @author
 * @function Pipelines
 **/

const Pipelines = (props) => {
  const [pipeline, setPipline] = useState([]);
  const [queryPipelines, setQueryPipelines] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    axios
      .get(`/api/v1/automation/pipeline`)
      .then((res) => {
        setLoading(false);
        let pipelineData = res.data;
        let newPipeline = pipelineData.map((pipe) => {
          let titleName = pipe.name.toUpperCase();
          pipe.titleLogo = titleName[0] + titleName[1];
          let newPipe = {
            titleLogo: pipe.titleLogo,
            id: pipe.id,
            title: pipe.name,
            platforms: pipe.cicd_platform ?? [],
            metric_value: pipe.metric_value,
            metric_name: pipe.metric_name,
            logos: pipe.logos,
          };
          return newPipe;
        });

        setPipline(newPipeline);
        setQueryPipelines(newPipeline);
      })
      .catch((err) => {
        console.error(err);
      });
    return;
  }, []);

  useEffect(() => {
    let re = new RegExp('.*?');
    if (props.searchTerm) {
      let searchText = props.searchTerm;
      let escapeSpecialChar = /[;\\/:*?\"<>|&'\[\]+()]/g;
      searchText = searchText.replaceAll(escapeSpecialChar);
      re = new RegExp(searchText, 'i');
    }
    setQueryPipelines(pipeline.filter((pipe) => pipe.title.search(re) !== -1));
  }, [props.searchTerm]);
  const setTitle = () => {
    if (queryPipelines.length && loading === false) {
      return 'All Results';
    } else if (loading === true) {
      return <Loader />;
    } else {
      return 'No Results Found';
    }
  };
  return (
    <>
      <div className='pipelineParentContainer'>
        <span className='heading'>{setTitle()}</span>

        <div className='pipelineItemsContainer'>
          {queryPipelines.map((pipe) => {
            return (
              <PipelineItem
                key={pipe.id}
                data={pipe}
              />
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Pipelines;
