import React from 'react';

import { NavLink, useLocation } from 'react-router-dom';

import SolutionIcon from '../../../assets/icons/SolutionIcon';
import InfiniteIcon from '../../../assets/icons/InfiniteIcon';
import MonitoringIcon from '../../../assets/icons/MonitoringIcon';
import SecurityOutlinedIcon from '@mui/icons-material/SecurityOutlined';
import { LuDatabaseBackup } from 'react-icons/lu';
import { LiaDocker } from 'react-icons/lia';
import './style.css';
/**
 * @author
 * @function SidebarItems
 **/

const SidebarItems = (props) => {
  const location = useLocation();

  var url = location.pathname;
  return (
    <div className='sidebarItemsContainer'>
      <NavLink
        className={({ isActive }) => (isActive ? 'btn active' : 'btn')}
        to='/pipelines'
      >
        <div
          className='itemDiv'
          {...props}
        >
          <div className='item-sub-div'>
            {url.includes('pipelines') ? (
              <InfiniteIcon color={'#fff'} />
            ) : (
              <InfiniteIcon color={'#878787'} />
            )}
          </div>
          <span style={{ marginLeft: '15px' }}>Pipelines</span>
        </div>
      </NavLink>
      <NavLink
        className={({ isActive }) => (isActive ? 'btn active' : 'btn')}
        to='/Infrastructure'
      >
        <div
          className='itemDiv'
          {...props}
        >
          <div className='item-sub-div'>
            {url.includes('Infrastructure') ? (
              <SolutionIcon color={'#fff'} />
            ) : (
              <SolutionIcon color={'#878787'} />
            )}
          </div>
          <span style={{ marginLeft: '15px' }}>
            Solution <br />
            Template
          </span>
        </div>
      </NavLink>
      <NavLink
        className={({ isActive }) => (isActive ? 'btn active' : 'btn')}
        to='/Monitoring'
      >
        <div
          className='itemDiv'
          {...props}
        >
          <div className='item-sub-div'>
            {url.includes('Monitoring') ? (
              <MonitoringIcon color={'#fff'} />
            ) : (
              <MonitoringIcon color={'#878787'} />
            )}
          </div>
          <span style={{ marginLeft: '15px' }}> Monitoring</span>
        </div>
      </NavLink>
      <NavLink
        className={({ isActive }) => (isActive ? 'btn active' : 'btn')}
        to='/security-and-compliance'
      >
        <div
          className='itemDiv'
          {...props}
        >
          <div
            className='item-sub-div'
            style={{ left: 0 }}
          >
            {url.includes('security-and-compliance') ? (
              <SecurityOutlinedIcon color={'#fff'} />
            ) : (
              <SecurityOutlinedIcon color={'#878787'} />
            )}
          </div>
          <span style={{ marginLeft: '15px' }}>
            Security And <br />
            Compliance
          </span>
        </div>
      </NavLink>
      <NavLink
        className={({ isActive }) => (isActive ? 'btn active' : 'btn')}
        to='/backup-and-dr'
      >
        <div
          className='itemDiv'
          {...props}
        >
          <div
            className='item-sub-div'
            style={{ left: 0 }}
          >
            {url.includes('backup-and-dr') ? (
              <LuDatabaseBackup
                color={'#fff'}
                size={28}
              />
            ) : (
              <LuDatabaseBackup
                color={'#878787'}
                size={24}
              />
            )}
          </div>
          <span style={{ marginLeft: '15px' }}>Backup And DR</span>
        </div>
      </NavLink>
      <NavLink
        className={({ isActive }) => (isActive ? 'btn active' : 'btn')}
        to='/containerization'
      >
        <div
          className='itemDiv'
          {...props}
        >
          <div
            className='item-sub-div'
            style={{ left: 0 }}
          >
            {url.includes('containerization') ? (
              <LiaDocker
                color={'#fff'}
                size={32}
              />
            ) : (
              <LiaDocker
                color={'#878787'}
                size={32}
              />
            )}
          </div>
          <span style={{ marginLeft: '15px' }}>Containerization</span>
        </div>
      </NavLink>
    </div>
  );
};

export default SidebarItems;
