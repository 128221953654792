import React from 'react';
import SidebarItems from './SidebarItems';
import { signOut } from '../../authentication';
import SidebarLogo from './SidebarLogo';
import './style.css';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import Button from '@mui/material/Button';

/**
 * @author
 * @function Sidebar
 **/

const Sidebar = (props) => {
  return (
    <div className='sidebar-main-div'>
      <div className='sidebar-sub-div'>
        <SidebarLogo />
        <SidebarItems />
        <div className='logout-div'>
          <Button
            onClick={signOut}
            startIcon={<ExitToAppIcon style={{ color: 'red' }} />}
          >
            Log Out
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
