import React from 'react';

const jenkins = () => {
  return (
    <>
      <svg
        version='1.1'
        id='Layer_1'
        viewBox='0 0 88.85 122.88'
        width='35px'
        height='35px'
      >
        <g>
          <path
            className='st11'
            style={{ fill: '#D43732' }}
            d='M87.53,59.21c0,24.35-19.3,44.08-43.1,44.08S1.34,83.56,1.34,59.21c0-24.35,19.3-44.08,43.1-44.08 S87.53,34.87,87.53,59.21L87.53,59.21z'
          />
          <path
            className='st4'
            style={{ fill: '#EF3E3A' }}
            d='M3.09,70.13c0,0-3.12-45.97,39.24-47.28l-2.96-4.93l-22.99,7.72l-6.57,7.55l-5.75,11L0.79,57l0.99,8.54 L3.09,70.13L3.09,70.13z'
          />
          <path
            className='st5'
            style={{ fill: '#221E1F' }}
            d='M13,91.48C4.97,83.26,0,71.91,0,59.38c0-12.53,4.97-23.89,13-32.11l0,0c8.03-8.22,19.15-13.31,31.43-13.31l0,0 c12.27,0,23.4,5.09,31.43,13.31l0,0c7.84,8.02,12.76,19.02,12.99,31.19v1.84c-0.24,12.17-5.16,23.17-12.99,31.19l0,0 c-8.03,8.22-19.15,13.31-31.43,13.31l0,0C32.16,104.8,
                    21.04,99.7,13,91.48L13,91.48L13,91.48z M0,59.38L0,59.38L0,59.38z M14.92,29.14C7.36,36.88,2.68,47.56,2.68,59.38l0,0c0,11.82,4.68,22.5,12.24,30.23l0,0c7.57,7.74,17.99,12.51,29.52,12.51l0,0 c11.53,0,21.95-4.77,29.52-12.51l0,0c7.56-7.73,12.24-18.42,12.24-30.23l0,0c0-11.82-4.68-22.5-12.24-30.24l0,0 c-7.57-7.73-17.99-12.51-29.52-12.51l0,0C32.91,16.64,22.48,21.41,14.92,29.14L14.92,29.14L14.92,29.14z'
          />
          <polygon
            className='st6'
            style={{ fill: '#F0D6B8' }}
            points='62.58,59.46 56.01,60.44 47.14,61.43 41.4,61.59 35.82,61.43 31.55,60.11 27.77,56.01 24.81,47.64 24.16,45.83 20.22,44.51 17.92,40.73 16.28,35.32 18.09,30.55 22.36,29.08 25.8,30.72 27.44,34.33 29.41,34 30.07,33.17 29.41,29.4 29.25,24.63 30.24,18.07 30.2,14.32 33.19,9.53 38.45,5.75 47.64,1.81 57.82,3.29 66.69,9.69 70.79,16.26 73.42,21.02 74.07,32.84 72.1,43.02 68.49,52.05 65.04,56.81 62.58,59.46'
          />
          <polygon
            className='st9'
            style={{ fill: '#345061' }}
            points='56.99,87.86 33.52,88.84 33.52,92.79 35.49,106.58 34.5,107.72 18.09,102.14 16.94,100.17 15.3,81.62 11.52,70.45 10.7,67.83 23.83,58.8 27.94,57.16 31.55,61.59 34.66,64.39 38.27,65.53 39.91,66.03 41.88,74.56 43.36,76.37 47.14,75.05 44.51,80.14 58.8,86.88 56.99,87.86'
          />
          <polygon
            className='st10'
            style={{ fill: '#6D6B6E' }}
            points='18.09,30.56 22.36,29.08 25.8,30.72 27.44,34.33 29.41,34 29.91,32.03 28.92,28.25 29.91,19.23 29.08,14.3 32.04,10.85 38.44,5.77 36.63,3.3 27.61,7.74 23.83,10.69 21.7,15.29 18.41,19.72 17.43,24.97 18.09,30.56'
          />
          <path
            className='st12'
            style={{ fill: '#DDD9D8' }}
            d='M24.81,15.13c0,0,2.46-6.07,12.31-9.03c9.85-2.96,0.49-2.13,0.49-2.13l-10.67,4.1l-4.1,4.1l-1.81,3.29 L24.81,15.13L24.81,15.13z'
          />
          <path
            className='st12'
            style={{ fill: '#DDD9D8' }}
            d='M19.89,29.41c0,0-3.45-11.49,9.69-13.14l-0.49-1.97l-9.03,2.13l-2.63,8.54l0.66,5.58L19.89,29.41L19.89,29.41 z'
          />
          <path
            className='st7'
            style={{ fill: '#F5E2CC' }}
            d='M25.14,44.68l2.15-2.08c0,0,0.97,0.11,1.13,1.26c0.16,1.15,0.66,11.49,7.72,17.08 c0.65,0.51-5.25-0.82-5.25-0.82l-5.25-8.21L25.14,44.68L25.14,44.68z'
          />
          <path
            className='st7'
            style={{ fill: '#F5E2CC' }}
            d='M55.36,41.56c0,0,0.38-4.97,1.72-4.59c1.34,0.38,1.34,1.72,1.34,1.72S55.16,40.79,55.36,41.56L55.36,41.56z'
          />
          <path
            className='st7'
            style={{ fill: '#F5E2CC' }}
            d='M68.98,23.34c0,0-2.71,0.57-2.96,2.96c-0.25,2.38,2.96,0.49,3.45,0.33L68.98,23.34L68.98,23.34z'
          />
          <path
            className='st7'
            style={{ fill: '#F5E2CC' }}
            d='M49.12,23.5c0,0-3.61,0.49-3.61,2.79c0,2.3,4.1,2.13,5.26,1.15L49.12,23.5L49.12,23.5z'
          />
          <path
            className='st7'
            style={{ fill: '#F5E2CC' }}
            d='M26.78,34.17c0,0-6.24-3.78-6.9-0.16c-0.66,3.61-2.13,6.24,0.98,10.01l-2.13-0.66l-1.97-5.09l-0.66-4.92 l3.78-3.94l4.27,0.33l2.46,1.97L26.78,34.17L26.78,34.17z'
          />
          <path
            className='st7'
            d='M29.74,23.83c0,0,2.79-14.45,16.91-17.24c11.63-2.3,17.73,0.49,20.03,3.12c0,0-10.34-12.31-20.19-8.54 c-9.85,3.78-17.08,10.67-16.91,15.11C29.86,23.83,29.74,23.83,29.74,23.83L29.74,23.83z'
          />
          <path
            className='st7'
            style={{ fill: '#F5E2CC' }}
            d='M67.83,11.35c0,0-4.76-0.16-4.92,4.1c0,0,0,0.65,0.33,1.32c0,0,3.78-4.27,6.07-1.97L67.83,11.35L67.83,11.35z'
          />
          <path
            className='st7'
            style={{ fill: '#F5E2CC' }}
            d='M46.98,17.22c0,0-0.82-6.55-6.41-2.74c-3.61,2.46-3.29,5.91-2.63,6.56c0.65,0.66,0.48,1.98,0.98,1.07 c0.5-0.91,0.33-3.86,2.14-4.68C42.87,16.61,45.83,15.69,46.98,17.22L46.98,17.22z'
          />
          <path
            className='st1'
            style={{ fill: '#4A728C' }}
            d='M31.55,61.59l-15.43,6.9c0,0,6.4,25.45,3.12,33.33l-2.3-0.82l-0.16-9.68L12.5,72.92l-1.81-5.09L26.78,57 L31.55,61.59L31.55,61.59z'
          />
          <path
            className='st1'
            style={{ fill: '#4A728C' }}
            d='M33.13,75.67l2.19,2.67v9.85H32.7c0,0-0.33-6.9-0.33-7.72c0-0.82,0.33-3.78,0.33-3.78L33.13,75.67L33.13,75.67 z'
          />
          <polygon
            className='st1'
            style={{ fill: '#4A728C' }}
            points='33.19,89.67 25.8,90 27.93,91.48 33.19,92.3 33.19,89.67'
          />
          <polygon
            className='st9'
            style={{ fill: '#345061' }}
            points='58.47,88.02 64.54,87.86 66.02,102.97 59.78,103.79 58.47,88.02'
          />
          <path
            className='st9'
            style={{ fill: '#345061' }}
            d='M60.11,88.02l9.2-0.49c0,0,3.78-9.52,3.78-10.01c0-0.49,3.29-13.79,3.29-13.79l-7.39-7.72l-1.48-1.32 l-3.94,3.94V73.9L60.11,88.02L60.11,88.02z'
          />
          <path
            className='st1'
            style={{ fill: '#4A728C' }}
            d='M64.21,86.88l-5.75,1.15l0.82,4.6c2.13,0.99,5.75-1.64,5.75-1.64L64.21,86.88L64.21,86.88z'
          />
          <polygon
            className='st1'
            style={{ fill: '#4A728C' }}
            points='64.38,58.15 75.87,66.68 76.2,62.74 67.5,54.7 64.38,58.15'
          />
          <polygon
            className='st8'
            style={{ fill: '#FFFFFF' }}
            points='38.88,120.37 35.49,106.58 33.8,96.4 33.52,88.84 48.9,88.03 58.47,88.03 57.6,105.27 59.07,118.56 58.91,121.03 46.44,122.01 38.88,120.37'
          />
          <path
            className='st12'
            style={{ fill: '#DDD9D8' }}
            d='M56.33,87.86c0,0-0.82,17.08,1.64,29.22c0,0-4.92,3.12-12.15,3.94l13.79-0.49l1.64-0.99l-1.97-26.93 l-0.49-5.75L56.33,87.86L56.33,87.86z'
          />
          <polygon
            className='st8'
            style={{ fill: '#FFFFFF' }}
            points='66.3,101.65 72.71,99.84 84.86,99.19 86.66,93.61 83.38,83.92 79.6,83.43 74.35,85.07 69.31,87.53 66.63,87.04 64.55,87.86 66.3,101.65'
          />
          <path
            className='st12'
            style={{ fill: '#DDD9D8' }}
            d='M66.19,98.37c0,0,4.27-1.97,4.93-1.81l-1.81-9.03l2.13-0.82c0,0,1.48,8.54,1.48,9.52c0,0,9.2,0.49,10.01,0.49 c0,0,1.97-3.78,1.48-7.72l1.81,5.25l0.16,2.96l-2.63,3.94l-2.96,0.66l-4.92-0.16l-1.64-2.13l-5.75,0.82l-1.81,0.66L66.19,98.37 L66.19,98.37z'
          />
          <path
            className='st8'
            style={{ fill: '#FFFFFF' }}
            d='M59.73,86.71l-3.61-9.2l-3.78-5.42c0,0,0.82-2.3,1.97-2.3c1.15,0,3.78,0,3.78,0l3.61,1.31l-0.33,6.07 L59.73,86.71L59.73,86.71z'
          />
          <path
            className='st12'
            style={{ fill: '#DDD9D8' }}
            d='M60.44,83.59c0,0-4.6-8.87-4.6-10.18c0,0,0.82-1.97,1.97-1.48c1.15,0.49,3.61,1.81,3.61,1.81v-3.12 l-5.58-1.15l-3.78,0.49l6.4,15.11l1.31,0.16L60.44,83.59L60.44,83.59z'
          />
          <polygon
            className='st8'
            style={{ fill: '#FFFFFF' }}
            points='40.36,61.92 35.82,61.43 31.55,60.12 31.55,61.59 33.63,63.89 40.19,66.85 40.36,61.92'
          />
          <path
            className='st12'
            style={{ fill: '#DDD9D8' }}
            d='M33.02,62.42c0,0,5.09,2.13,6.73,1.64l0.16,1.97l-4.6-0.99l-2.79-1.97L33.02,62.42L33.02,62.42z'
          />
          <path
            className='st11'
            style={{ fill: '#D43732' }}
            d='M66.28,70.36c-2.79-0.08-5.3-0.41-7.51-1.03c0.15-0.9-0.13-1.79,0.09-2.44c0.62-0.44,1.64-0.43,2.57-0.54 c-0.8-0.39-1.93-0.55-2.86-0.33c-0.02-0.63-0.3-1.01-0.47-1.51c1.57-0.56,5.27-4.23,7.34-3.01c0.99,0.58,1.41,3.88,1.49,5.48 C67.01,68.32,66.83,69.66,66.28,70.36L66.28,70.36z'
          />
          <path
            className='st0'
            style={{ stroke: '#D43732', strokeWidth: '0.7881' }}
            d='M66.28,70.36c-2.79-0.08-5.3-0.41-7.51-1.03c0.15-0.9-0.13-1.79,0.09-2.44c0.62-0.44,1.64-0.43,2.57-0.54 c-0.8-0.39-1.93-0.55-2.86-0.33c-0.02-0.63-0.3-1.01-0.47-1.51c1.57-0.56,5.27-4.23,7.34-3.01c0.99,0.58,1.41,3.88,1.49,5.48 C67.01,68.32,66.83,69.66,66.28,70.36L66.28,70.36z'
          />
          <path
            className='st11'
            style={{ fill: '#D43732' }}
            d='M52.97,65.8c-0.01,0.21-0.02,0.42-0.03,0.64c-0.87,0.57-2.28,0.57-3.23,1.04c1.41,0.06,2.52,0.4,3.47,0.88 c-0.02,0.53-0.04,1.06-0.06,1.59c-1.6,1.09-3.05,2.72-4.93,3.74c-0.89,0.48-4,1.73-4.95,1.51c-0.53-0.12-0.58-0.79-0.8-1.41 c-0.45-1.34-1.5-3.47-1.6-5.48c-0.12-2.55-0.37-6.81,2.37-6.28c2.21,0.42,4.79,1.44,6.5,2.38C50.77,64.98,51.38,65.69,52.97,65.8 L52.97,65.8z'
          />
          <path
            className='st0'
            style={{ stroke: '#D43732', strokeWidth: '0.7881' }}
            d='M52.97,65.8c-0.01,0.21-0.02,0.42-0.03,0.64c-0.87,0.57-2.28,0.57-3.23,1.04c1.41,0.06,2.52,0.4,3.47,0.88 c-0.02,0.53-0.04,1.06-0.06,1.59c-1.6,1.09-3.05,2.72-4.93,3.74c-0.89,0.48-4,1.73-4.95,1.51c-0.53-0.12-0.58-0.79-0.8-1.41 c-0.45-1.34-1.5-3.47-1.6-5.48c-0.12-2.55-0.37-6.81,2.37-6.28c2.21,0.42,4.79,1.44,6.5,2.38C50.77,64.98,51.38,65.69,52.97,65.8 L52.97,65.8z'
          />
          <path
            className='st11'
            style={{ fill: '#D43732' }}
            d='M54.55,68.84c-0.24-1.38-0.52-1.78-0.41-2.99C57.83,63.39,58.52,70.08,54.55,68.84L54.55,68.84z'
          />
          <path
            className='st0'
            style={{ stroke: '#D43732', strokeWidth: '0.7881' }}
            d='M54.55,68.84c-0.24-1.38-0.52-1.78-0.41-2.99C57.83,63.39,58.52,70.08,54.55,68.84L54.55,68.84z'
          />
          <path
            className='st4'
            style={{ fill: '#EF3E3A' }}
            d='M59.9,69.97c0,0-1.15-1.64-0.33-2.13c0.82-0.49,1.64,0,2.13-0.82c0.49-0.82,0-1.32,0.16-2.3 c0.16-0.98,0.99-1.15,1.81-1.31c0.82-0.16,3.12-0.49,3.45,0.33l-0.98-2.96l-1.97-0.65l-6.24,3.61l-0.33,1.81v3.61L59.9,69.97 L59.9,69.97z'
          />
          <path
            className='st4'
            style={{ fill: '#EF3E3A' }}
            d='M42.66,75.38c-0.2-2.56-0.4-5.12-0.64-7.68c-0.35-3.82,0.92-3.16,4.23-3.16c0.51,0,3.11,0.61,3.3,0.99 c0.9,1.83-1.5,1.42,1.03,2.8c2.13,1.16,5.91-0.71,5.04-3.3c-0.48-0.58-2.52-0.18-3.24-0.56c-1.28-0.66-2.57-1.33-3.85-1.99 c-1.63-0.85-5.4-2.08-7.14-0.9c-4.41,3,0.28,10.49,1.85,13.62L42.66,75.38L42.66,75.38z'
          />
          <path
            className='st5'
            style={{ fill: '#221E1F' }}
            d='M46.98,17.22c-4.48-1.04-6.7,1.87-8.05,4.9c-1.21-0.3-0.73-1.94-0.42-2.78c0.8-2.2,4.03-5.13,6.66-4.74 C46.3,14.76,47.84,15.8,46.98,17.22L46.98,17.22z'
          />
          <path
            className='st5'
            style={{ fill: '#221E1F' }}
            d='M68.78,22.31c0.07,0,0.14,0,0.21,0.01c1.01,2.1,1.89,4.32,3.16,6.18c-0.86,1.99-6.47,3.75-6.38,0.18 c1.22-0.53,3.31-0.11,4.39-0.79C69.54,26.18,68.64,24.73,68.78,22.31L68.78,22.31z'
          />
          <path
            className='st5'
            style={{ fill: '#221E1F' }}
            d='M49.27,22.36c0.96,1.76,1.27,3.6,2.63,4.94c0.62,0.6,1.81,1.33,1.22,2.99c-0.14,0.39-1.15,1.27-1.73,1.44 c-2.13,0.63-7.1,0.13-5.42-2.53c1.76,0.08,4.13,1.14,5.45-0.13C50.41,27.45,48.61,24.25,49.27,22.36L49.27,22.36z'
          />
          <path
            className='st5'
            style={{ fill: '#221E1F' }}
            d='M67.98,40.24c-3.21,2.06-6.79,4.31-12.05,3.78c-1.12-0.98-1.55-3.15-0.46-4.59c0.57,0.98,0.21,2.77,1.79,3.04 c2.98,0.51,6.46-1.83,8.6-2.64c1.33-2.25-0.11-3.07-1.31-4.52c-2.46-2.96-5.75-6.63-5.63-11.06c1-0.72,1.08,1.1,1.22,1.43 c1.28,3,4.51,6.84,6.87,9.41c0.58,0.63,1.53,1.24,1.63,1.65C68.95,37.98,67.86,39.43,67.98,40.24L67.98,40.24z'
          />
          <path
            className='st5'
            style={{ fill: '#221E1F' }}
            d='M25.66,38.07c-1-0.58-1.25-3.1-2.43-3.18c-1.69-0.1-1.38,3.28-1.37,5.26c-1.16-1.05-1.36-4.3-0.51-5.97 c-0.98-0.48-1.41,0.53-1.95,0.88C20.1,30.03,26.77,32.73,25.66,38.07L25.66,38.07z'
          />
          <path
            className='st5'
            style={{ fill: '#221E1F' }}
            d='M70.03,42.34c-1.49,2.85-3.61,5.98-7.99,6.07c-0.09-0.92-0.16-2.31,0.01-2.87 C65.39,45.22,67.46,43.51,70.03,42.34L70.03,42.34z'
          />
          <path
            className='st5'
            d='M49.02,44.18c2.8,1.47,7.93,1.63,11.74,1.52c0.2,0.83,0.2,1.86,0.21,2.88C56.08,48.82,50.3,47.62,49.02,44.18 L49.02,44.18z'
          />
          <path
            className='st5'
            style={{ fill: '#221E1F' }}
            d='M48.49,46.93c1.94,4.86,8.58,4.29,14.19,4.16c-0.25,0.63-0.78,1.37-1.45,1.65c-1.8,0.73-6.75,1.29-9.25-0.04 c-1.58-0.84-2.6-2.74-3.46-3.86C48.1,48.31,46.02,46.93,48.49,46.93L48.49,46.93z'
          />
          <path
            className='st2'
            style={{ fill: '#81B0C4' }}
            d='M67.75,73.71c-2.27,3.89-4.44,7.88-7.13,11.31c1.13-3.32,1.61-8.88,1.78-13.11 C64.76,70.8,66.78,72.16,67.75,73.71L67.75,73.71z'
          />
          <path
            className='st5'
            style={{ fill: '#221E1F' }}
            d='M79.96,87.68c-2.54,0.51-4.32,2.98-6.81,2.82C74.52,88.58,76.91,87.77,79.96,87.68L79.96,87.68z'
          />
          <path
            className='st5'
            style={{ fill: '#221E1F' }}
            d='M81.08,91.66c-2.07,0.22-4.5,0.56-6.61,0.38C75.47,90.52,79.31,91.05,81.08,91.66L81.08,91.66z'
          />
          <path
            className='st5'
            style={{ fill: '#221E1F' }}
            d='M81.8,95.09c-2.33,0.05-5.22,0-7.43-0.18C75.68,93.5,80.29,94.39,81.8,95.09L81.8,95.09z'
          />
          <path
            className='st12'
            style={{ fill: '#DDD9D8' }}
            d='M63.37,104.47c0.33,2.92,1.49,5.89,1.35,9.09c-1.29,0.43-2.02,0.81-3.75,0.81c-0.12-2.72-0.49-6.88-0.37-9.47 C61.44,104.95,62.69,104.29,63.37,104.47L63.37,104.47z'
          />
          <path
            className='st6'
            style={{ fill: '#F0D6B8' }}
            d='M59.62,61.39c-1.17,0.76-2.16,1.71-3.29,2.53c-2.49,0.12-3.85-0.17-5.67-1.6c0.03-0.11,0.21-0.06,0.22-0.2 C53.54,63.3,56.93,61.63,59.62,61.39L59.62,61.39z'
          />
          <path
            className='st2'
            style={{ fill: '#81B0C4' }}
            d='M45.63,79.55c0.73-3.17,3.6-4.82,6.21-6.56c2.69,3.41,4.32,7.8,6.13,12.04 C53.71,83.74,49.36,81.66,45.63,79.55L45.63,79.55z'
          />
          <path
            className='st5'
            style={{ fill: '#221E1F' }}
            d='M71.14,84.9c3.98-1.98,11.43-5.33,13.92,0.01c0.92,1.97,2,5.3,2.48,7.32c0.67,2.87-0.73,8.89-3.68,9.85 c-2.6,0.85-5.63,0.8-8.77,0.17c-0.37-0.31-0.78-0.84-1.07-1.4c-2.23-0.09-4.33,0.12-6.1,1.04c0.17,1.66-0.95,1.92-2,2.26 c-0.78,3.08,1.56,7.11,0.99,9.92c-0.4,2-2.86,2.32-4.67,2.69c-0.06,1.11,0.08,2.04,0.2,2.98c-0.41,1.53-2.27,2.39-4.04,2.61 c-2.53,0.3-5.64,0.53-8.8,0.52c-4.07-0.01-8.22-0.4-11.35-1.53c-1.56-3.81-2.78-8.45-4.07-12.81c-5.43,
                    0.58-9.83-2.34-13.97-4.26 c-1.43-0.67-3.42-1.03-3.96-2.17c-0.52-1.1-0.31-3.23-0.43-5.23c-0.33-5.11-0.61-10.04-1.96-15.27c-0.61-2.35-1.67-4.42-2.4-6.68 c-0.68-2.1-1.87-4.69-2.18-6.78c-0.46-3.1,2.46-3.27,4.32-4.62c2.89-2.08,5.15-3.23,8.27-5.1c0.93-0.56,3.71-1.96,4.03-2.61 c0.63-1.29-1.08-3.09-1.54-4.1c-0.72-1.59-1.1-2.94-1.21-4.51c-2.62-0.41-4.61-1.97-5.81-3.73c-1.99-2.91-3.36-8.29-1.65-12.39 c0.13-0.32,0.81-0.96,0.91-1.45c0.2-0.98-0.37-2.27-0.4-3.31c-0.18-5.32,
                    0.9-9.91,4.49-11.52c1.45-5.8,6.66-7.72,11.57-10.6 c1.83-1.08,3.86-1.76,5.94-2.53c7.49-2.76,18.98-2.24,25.2,2.46c2.63,2,6.85,6.2,8.36,9.25c3.98,8.05,3.7,21.49,0.92,31.28 c-0.38,1.31-0.92,3.25-1.68,4.82c-0.53,1.1-2.17,3.31-1.97,4.28c0.21,1,3.74,3.69,4.51,4.43c1.37,1.32,3.96,3.07,4.17,4.73 c0.23,1.77-0.78,4.19-1.29,5.9C74.74,74.54,73.08,79.81,71.14,84.9L71.14,84.9z M61.64,6.31c-5.1-2.88-13.73-5.04-19.15-2.33 c-4.37,2.18-10.29,5.79-12.31,10.36c1.88,4.41-0.56,8.46-0.71,
                    12.94c-0.08,2.38,1.12,4.47,1.22,7.06c-0.64,1.06-2.62,1.2-3.97,1.12 c-0.46-2.29-1.26-4.88-3.63-5.14c-3.34-0.36-5.79,2.4-5.94,5.29c-0.18,3.4,2.61,9.04,6.57,8.65c1.53-0.15,1.91-1.68,3.57-1.67 c0.9,1.8-1.39,2.37-1.63,3.65c-0.06,0.33,0.19,1.64,0.33,2.25c0.72,2.97,2.33,6.82,3.9,9.08c2,2.87,5.94,3.3,10.17,3.58 c0.76-1.63,3.54-1.5,5.36-1.07c-2.18-0.86-4.2-2.95-5.87-4.8c-1.93-2.12-3.88-4.39-3.98-7.17c3.64,5.05,6.64,9.45,13.26,11.67 c5,1.68,10.85-0.77,14.7-3.47c1.6-1.12,2.54-2.91,
                    3.68-4.54c4.25-6.1,6.22-14.82,5.79-23.27c-0.18-3.48-0.17-6.95-1.34-9.3 c-1.22-2.45-5.35-4.65-7.77-2.43c-0.45-2.38,2.01-3.86,4.9-3C66.74,11.15,64.57,7.96,61.64,6.31L61.64,6.31L61.64,6.31z M36.08,5 c-4.91,1.39-11.21,4.96-13.22,9.37c1.56-0.23,2.65-1.01,4.19-1.11c0.58-0.04,1.34,0.25,2.01,0.08c1.33-0.33,2.46-3.32,3.47-4.44 c0.98-1.09,2.16-1.55,2.97-2.54c0.52-0.25,1.29-0.23,1.31-1.01C36.59,5.1,36.35,4.92,36.08,5L36.08,5z M20.56,18.85 c-2.17,2.39-1.71,6.86-1.45,10.04c3.92-2.47,
                    9.13,0.2,9.08,4.39c1.87-0.05,0.7-2.34,0.36-3.81c-1.11-4.82,1.87-10.05,0.13-14.45 C25.32,15.27,22.56,16.65,20.56,18.85L20.56,18.85z M64.06,59.22c4.55-2.21,5.38,8.25,3.59,11.62c0.28,1,1.23,1.39,1.61,2.3 c-2.54,4.55-5.36,8.8-7.95,13.3c1.93-1.2,4.68-0.22,6.95-1.11c0.83-0.33,1.43-2.23,2.06-3.75c1.73-4.18,3.54-9.45,4.35-13.44 c0.18-0.91,0.68-2.89,0.57-3.7c-0.2-1.45-2.16-2.52-3.16-3.42c-1.84-1.66-3-3.11-4.92-4.65C66.37,57.52,64.7,58.28,64.06,59.22 L64.06,59.22L64.06,59.22z M30.97,
                    62.83c-0.45-0.32-3.47-4.25-3.88-4.08c-5.46,2.16-10.57,5.88-15.14,9.4 c4.35,9.34,6.11,20.78,6.42,31.81c4.98,2.33,9.37,5.69,16.14,6.04c-0.78-5.54-1.5-10.48-1.94-15.7c-1.7-0.71-4.14,0.03-5.73-0.22 c-0.01-1.92,2.43-0.84,2.63-2.13c0.15-0.97-1.34-1.05-0.86-2.58c1.25,0.45,1.9,1.45,3.22,1.83c1.21-2.65-0.01-7.34,0.16-9.56 c0.03-0.42,0.21-2.31,1.14-1.98c0.83,0.3-0.05,5.02,0.04,7.12c0.08,1.93-0.23,3.8,0.55,5.01c6.54-0.89,13.18-1.46,20.26-1.66 c-1.56-0.67-3.4-1.3-5.43-2.44c-1.1-0.62-4.56-1.91-4.88-2.95c-0.51-1.67,
                    1.32-2.55,1.64-3.97c-3.3,1.8-3.95-1.72-4.72-4.22 c-0.71-2.26-1.11-3.95-1.29-5.26C36.46,65.94,33.42,64.57,30.97,62.83L30.97,62.83L30.97,62.83z M66.95,66.99 c-0.08-1.6-0.5-4.9-1.49-5.48c-2.08-1.21-5.78,2.45-7.34,3.01c0.17,0.49,0.45,0.88,0.47,1.51c0.93-0.23,2.05-0.07,2.86,0.33 c-0.93,0.1-1.96,0.1-2.57,0.54c-0.23,0.65,0.05,1.54-0.09,2.44c2.2,0.62,4.72,0.95,7.51,1.03C66.83,69.66,67.01,68.32,66.95,66.99 L66.95,66.99z M50.88,62.11c0,0.14-0.19,0.09-0.22,0.2c1.83,1.43,3.19,1.72,5.67,1.6c1.12-0.81,2.12-1.77,
                    3.29-2.53 C56.93,61.63,53.54,63.3,50.88,62.11L50.88,62.11z M33.36,61.43c-1.42,1.44,3.98,3.41,5.71,3.52c-0.01-0.91,0.52-1.77,0.41-2.43 C37.44,62.16,34.75,62.4,33.36,61.43L33.36,61.43z M49.72,64.41c-1.71-0.94-4.29-1.96-6.5-2.38c-2.74-0.52-2.49,3.74-2.37,6.28 c0.09,2.01,1.14,4.14,1.6,5.48c0.21,0.63,0.26,1.29,0.8,1.41c0.95,0.22,4.06-1.02,4.95-1.51c1.88-1.02,3.33-2.65,4.93-3.74 c0.02-0.53,0.04-1.06,0.06-1.59c-0.96-0.48-2.06-0.82-3.47-0.88c0.96-0.48,2.36-0.47,3.23-1.04c0.01-0.21,0.02-0.42,0.03-0.64 C51.38,
                    65.69,50.77,64.98,49.72,64.41L49.72,64.41z M54.13,65.85c-0.11,1.21,0.17,1.61,0.41,2.99 C58.52,70.08,57.83,63.39,54.13,65.85L54.13,65.85z M57.37,70.12c-1.02-0.11-1.89,1.17-3.21,0.62c-0.3,0.33-0.58,0.7-0.89,1.03 c2.93,3.53,4.26,8.54,6.52,12.69c1.22-3.99,1.07-8.35,1.34-12.7C59.46,71.86,58.54,70.25,57.37,70.12L57.37,70.12z M62.4,71.91 c-0.17,4.24-0.66,9.79-1.78,13.11c2.69-3.43,4.87-7.42,7.13-11.31C66.78,72.16,64.76,70.8,62.4,71.91L62.4,71.91z M57.96,85.02 c-1.8-4.24-3.44-8.62-6.13-12.04c-2.61,1.75-5.47,3.39-6.21,
                    6.56C49.36,81.66,53.71,83.74,57.96,85.02L57.96,85.02z M81.8,100.62 c2.57-0.62,4.18-3.76,3.46-6.99c-0.48-2.17-1.34-6.25-2.26-7.63c-0.68-1.02-2.52-2.37-3.98-1.43c-2.39,1.53-6.59,1.97-8.33,3.82 c0.87,2.91,1.14,6.89,1.5,10.58c2.98,0.19,6.65-0.82,9.13,0.25c-1.73,0.56-3.98,0.57-5.47,1.38 C77.07,101.19,79.93,101.06,81.8,100.62L81.8,100.62L81.8,100.62z M68.56,88.52c-0.82-0.19-1.77,0-2.55,0.01 c0.37,3.66,1.26,7.7,1.57,11.54c1.23,0.04,1.88-0.54,2.89-0.74C70.53,95.95,70.19,91.31,68.56,88.52L68.56,88.52L68.56,88.52z M59.81,
                    89.24c-0.06,4.49,0.2,8.92,0.54,13.41c1.72-0.26,2.9-0.43,4.5-0.78c-0.52-4.33-0.45-9.2-1.52-13.02 C62.11,88.86,61.03,88.83,59.81,89.24L59.81,89.24L59.81,89.24z M33.74,90.15c1.14,10.45,2.78,19.24,5.81,28.5 c6.7,2.03,14.79,2.21,20.71,0.38c-1.09-5.23-0.61-11.58-1.25-17.16c-0.48-4.19-0.24-8.41-0.89-12.68 C50.94,87.68,40.79,88.83,33.74,90.15L33.74,90.15L33.74,90.15z M60.59,104.89c-0.11,2.6,0.26,6.75,0.38,9.47 c1.72,0,2.46-0.38,3.75-0.81c0.15-3.2-1.02-6.16-1.35-9.09C62.69,104.29,61.44,104.95,60.59,104.89L60.59,104.89L60.59,104.89z'
          />
          <path
            className='st7'
            style={{ fill: '#F5E2CC' }}
            d='M44.57,45.42c0.22-0.29,1.4-0.72,3.07,0.07c0,0-1.97,0.33-1.81,3.62l-0.82-0.16 C45.01,48.95,44.16,45.97,44.57,45.42L44.57,45.42z'
          />
          <path
            className='st3'
            style={{ fill: '#1D1919' }}
            d='M58.96,73.66c0,0.5-0.4,0.9-0.9,0.9c-0.5,0-0.9-0.4-0.9-0.9c0-0.5,0.4-0.9,0.9-0.9 C58.56,72.75,58.96,73.16,58.96,73.66L58.96,73.66z'
          />
          <path
            className='st3'
            style={{ fill: '#1D1919' }}
            d='M59.87,77.85c0,0.5-0.4,0.9-0.9,0.9s-0.9-0.4-0.9-0.9c0-0.5,0.4-0.9,0.9-0.9 C59.46,76.94,59.87,77.34,59.87,77.85L59.87,77.85z'
          />
        </g>
      </svg>
    </>
  );
};

export default jenkins;
