import React from 'react';
import Card from '../../../components/Card';
import './style.css';
import { Link } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';

/**
 * @author
 * @function MonitoringItem
 **/

const MonitoringItem = (props) => {
  let metrics = props.data.metric_value;
  let substringMetrics = metrics;
  if (metrics && metrics.length > 20) substringMetrics = metrics.substring(0, 20) + '...';
  let fixedValues = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', '%', '-', '>'];

  return (
    <Card>
      <Link
        className='main-card-div'
        style={{ textDecoration: 'none' }}
        to={'/Monitoring/' + props.data.id}
      >
        <div className='card-part-3'>
          <div className='infraPiplineLogo'>
            <span style={{ fontWeight: 'bold' }}>{props.data.titleLogo}</span>{' '}
          </div>
          <div>
            {props.data.id && (
              <div
                style={{
                  textDecoration: 'none',
                  color: '#000',
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                }}
                to={'/Infrastructure/' + props.data.id}
              >
                {props.data.title && <span className='InfraPipelineName'>{props.data.title}</span>}
              </div>
            )}
          </div>
        </div>
        <div className='card-part-1'>
          <div className='pipeline-logo-div'></div>
          <div className='ItemHeader'>
            <div className='metrics-value'>
              {substringMetrics &&
                substringMetrics.split('').map((character) => {
                  return (
                    <>
                      <Tooltip title={metrics}>
                        <span
                          style={{
                            fontSize: fixedValues.includes(character) ? '20px' : '15px',
                            fontWeight: 'bold',
                            color: fixedValues.includes(character) ? 'red' : 'black',
                          }}
                        >
                          {character}
                        </span>
                      </Tooltip>
                    </>
                  );
                })}
            </div>
            {props.data.metric_name && <div className='metrics-name'>{props.data.metric_name}</div>}
          </div>
        </div>
        {props.data.logos && (
          <div className='card-part-2'>
            {props.data.logos.map((logo) => {
              return (
                <>
                  <div className='Companies'>
                    <div className='Companies_image'>
                      <img
                        style={{ width: '2.9rem', height: '1.4rem', objectFit: 'contain' }}
                        src={logo}
                        alt='company-logo'
                      />
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        )}
      </Link>
    </Card>
  );
};

export default MonitoringItem;
