import { useState, useEffect } from 'react';
import { getCurrentUser } from 'aws-amplify/auth';

export const useIsAuthenticated = () => {
  const [isLaoding, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getCurrentUser()
      .then(() => {
        setIsAuthenticated(true);
      })
      .catch(() => {
        setIsAuthenticated(false);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [getCurrentUser]);

  return { isLaoding, isAuthenticated };
};
