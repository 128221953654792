import React from 'react';

const codepipeline = () => {
  return (
    <>
      <svg
        width='35px'
        height='35px'
        viewBox='0 0 256 310'
        version='1.1'
        preserveAspectRatio='xMidYMid'
      >
        <g>
          <path
            d='M36.08,262.013 L0,244.832 L0,64 L36.08,45.96 L36.211,46.147 L36.451,261.648 L36.08,262.013'
            fill='#4B612C'
          ></path>
          <path
            d='M168.376,226.362 L36.08,262.013 L36.08,45.96 L168.376,82.04 L168.376,226.362'
            fill='#759C3E'
          ></path>
          <path
            d='M59.705,124.134 L128,115.544 L129.667,117 L129.667,190.333 L128,192.859 L59.705,184.698 L59.705,124.134'
            fill='#4B612C'
          ></path>
          <path
            d='M256,109.53 L193.718,115.973 L59.705,94.497 L127.167,72.5 L256,109.53'
            fill='#3C4929'
          ></path>
          <path
            d='M256,199.731 L193.718,193.289 L59.705,214.765 L132.167,233.167 L256,199.731'
            fill='#B7CA9D'
          ></path>
          <path
            d='M59.705,274.899 L128,309.262 L130.887,232.694 L59.705,214.765 L59.705,274.899'
            fill='#4B612C'
          ></path>
          <path
            d='M256,245.262 L128,309.262 L128,231.946 L256,199.731 L256,245.262'
            fill='#759C3E'
          ></path>
          <path
            d='M59.705,33.933 L128,0 L128.495,0.698 L128.285,76.844 L128,77.315 L59.705,94.497 L59.705,33.933'
            fill='#4B612C'
          ></path>
          <path
            d='M256,176.966 L128,192.859 L128,115.544 L256,131.866 L256,176.966'
            fill='#759C3E'
          ></path>
          <path
            d='M256,109.53 L128,77.315 L128,0 L256,64 L256,109.53'
            fill='#759C3E'
          ></path>
        </g>
      </svg>
    </>
  );
};

export default codepipeline;
