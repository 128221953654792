import React from 'react';
import Card from '../../../components/Card';
import './style.css';
import { Link } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';

/**
 * @author
 * @function InfraItem
 **/

const InfraItem = (props) => {
  const { data, path } = props;
  let metrics = data.metric_value;
  let substringMetrics = metrics;
  if (metrics && metrics.length > 20) substringMetrics = metrics.substring(0, 20) + '...';
  let fixedValues = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', '%', '-', '>'];
  const fullpath = '/' + `${path}` + '/' + data.id;

  return (
    <Card>
      <Link
        className='main-card-div'
        style={{ textDecoration: 'none' }}
        to={fullpath}
      >
        <div className='card-part-3'>
          <div className='infraPiplineLogo'>
            <span style={{ fontWeight: 'bold' }}>{data.titleLogo}</span>{' '}
          </div>
          <div>
            {data.id && (
              <div
                style={{
                  textDecoration: 'none',
                  color: '#000',
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                }}
                to={'/Infrastructure/' + data.id}
              >
                {data.title && <span className='InfraPipelineName'>{data.title}</span>}
              </div>
            )}
          </div>
        </div>
        <div className='card-part-1'>
          <div className='pipeline-logo-div'></div>
          <div className='ItemHeader'>
            <div className='metrics-value'>
              {substringMetrics &&
                substringMetrics.split('').map((character, index) => {
                  return (
                    <Tooltip
                      title={metrics}
                      key={index}
                    >
                      <span
                        style={{
                          fontSize: fixedValues.includes(character) ? '20px' : '15px',
                          fontWeight: 'bold',
                          color: fixedValues.includes(character) ? 'red' : 'black',
                        }}
                      >
                        {character}
                      </span>
                    </Tooltip>
                  );
                })}
            </div>
            {data.metric_name && (
              <div
                className='metrics-name'
                key={data.metric_name}
              >
                {data.metric_name}
              </div>
            )}
          </div>
        </div>
        {data.logos && (
          <div className='card-part-2'>
            {data.logos.map((logo) => {
              return (
                <>
                  <div className='Companies'>
                    <div className='Companies_image'>
                      <img
                        style={{ width: '2.9rem', height: '1.4rem', objectFit: 'contain' }}
                        src={logo}
                        alt='company-logo'
                      />
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        )}
      </Link>
    </Card>
  );
};

export default InfraItem;
