import React, { useState, useEffect } from 'react';
import './InfraDetails.css';
import axios from 'axios';
import { saveAs } from 'file-saver';
import Infrastructure from './InfraDetails';
import InputLabel from '@mui/material/InputLabel';
import { makeStyles } from '@mui/styles';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Fade } from 'react-awesome-reveal';
import { useNavigate, useParams } from 'react-router-dom';
import Button from '@mui/material/Button';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import Loader from '../../components/Loader/BouncingDotsLoader';

/**
 * @author
 * @function InfraDetails
 **/

const useStyles = makeStyles((theme) => ({
  button: {
    display: 'block',
    marginTop: theme.spacing(2),
  },
  formControl: {
    minWidth: 120,
  },
}));

const InfraDetails = () => {
  const [InfraSolution, setInfraSolution] = useState({
    solution_name: '',
    id: '',
    solution_image_url: '',
    solution_description: '',
    used_by: [],
    cicd_platforms: [],
  });

  const classes = useStyles();
  const [companies, setCompanies] = useState([]);
  const [platform, setPlatform] = useState('');
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [displa, setdisplay] = useState('block');
  const [dindex, setdindex] = useState(0);
  const Display_testimonials = (x) => {
    setdindex(x);
    let newDisplay = 'block';
    setdisplay(newDisplay);
  };

  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    axios
      .get(`api/v1/automation/solutions/${id}`)
      .then((res) => {
        setLoading(false);
        setInfraSolution({
          solution_name: res.data.solution_name,
          id: res.data.id,
          solution_image_url: res.data.solution_image_url,
          solution_description: res.data.solution_description,
          used_by: res.data.used_by,
          cicd_platforms: res.data.cicd_platforms,
        });
        setCompanies(res.data.used_by);
      })
      .catch((err) => {
        console.error(err);
        return <Infrastructure />;
      });
  }, []);

  const handleChange = (event) => {
    setPlatform(event.target.value);
  };

  const clickHandler = async () => {
    if (platform === '') {
    } else {
      axios
        .get(`/api/v1/automation/solutions/${id}/download/${platform}`)
        .then(async (res) => {
          try {
            const downloadFile = await fetch(res.data.url);
            const blob = await downloadFile.blob();
            saveAs(blob, InfraSolution.solution_name + ' ' + platform + '.zip');
          } catch (e) {
            console.error(e);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const setTitle = () => {
    if (loading === true) {
      return <Loader />;
    }
  };

  let dropdownContent = InfraSolution.cicd_platforms.map((ddItem) => {
    return (
      <MenuItem
        value={ddItem.name}
        key={ddItem.name}
      >
        {ddItem.name}
      </MenuItem>
    );
  });

  return (
    <div className='infraDetailsContainer'>
      <div className='infraDetailsDiv'>
        <div className='DivBack'>
          <button
            title='Back to Infrastructure'
            className='backButton'
            onClick={() => navigate(-1)}
          >
            <KeyboardBackspaceIcon />
          </button>
        </div>
        <div className='infraImg'>
          <span className='heading'>{setTitle()}</span>
          <img
            className='imgresize'
            alt=''
            src={InfraSolution.solution_image_url}
          />
        </div>
        <span className='infraName'>
          {InfraSolution.solution_name}
          <div className='cloud_icon'>
            {companies.map((item, index) => {
              return (
                <>
                  <img
                    style={{
                      width: '8vh',
                      height: '6vh',
                      borderRadius: '10px',
                      padding: '0.5vh',
                      objectFit: 'contain',
                      backgroundColor: '#ffffff',
                    }}
                    src={item.cloud_icon}
                    alt='cloud-logo'
                  />{' '}
                  &nbsp;
                  <img
                    style={{
                      width: '8vh',
                      height: '6vh',
                      borderRadius: '10px',
                      padding: '0.5vh',
                      objectFit: 'contain',
                      backgroundColor: '#ffffff',
                    }}
                    src={item.category_icon}
                    alt='category-logo'
                  />
                </>
              );
            })}
          </div>
        </span>
        <span className='companiesHeading'>Companies using this Solution Infrastructure are</span>
        <br />
        <div className='CompaniesMainDiv'>
          {companies.map((item, index) => {
            return (
              <>
                <div className='Companies'>
                  <div
                    className='Companies_image'
                    onMouseEnter={() => Display_testimonials(index)}
                    style={{
                      backgroundColor: '#ffffff',
                      borderRadius: '10px',
                      maxHeight: 'auto',
                      boxShadow: '13px -6px 43px #00000029',
                    }}
                  >
                    <img
                      style={{ width: '12vh', height: '7.7vh' }}
                      src={item.image_url}
                      alt='company-logo'
                    />
                  </div>
                  <div
                    className='triangle'
                    style={{ display: index === dindex ? 'block' : 'none' }}
                  ></div>
                </div>
              </>
            );
          })}
        </div>
        <Fade>
          <div style={{ display: displa }}>
            {companies.map((item, index) => {
              if (index === dindex)
                return (
                  <>
                    <div className='testimonials'>
                      <p
                        style={{
                          color: '#272727',
                          marginLeft: '2%',
                          marginTop: '2%',
                        }}
                      >
                        {`"${item.testimonial}"`}
                      </p>
                      <div style={{ marginLeft: '2%' }}>
                        <p
                          style={{
                            color: 'rgb(251, 108, 81)',
                            fontWeight: 'bold',
                            marginBlockEnd: '0.8vh',
                            marginBlockStart: '1.5vh',
                          }}
                        >
                          Industry:
                        </p>
                        <p
                          style={{
                            marginBlockEnd: '2vh',
                            marginBlockStart: '0.6vh',
                          }}
                        >
                          {item.domain}
                        </p>
                      </div>
                    </div>
                  </>
                );
            })}
          </div>
        </Fade>
      </div>
      <div className='MainInfraDescrption'>
        <div className='infraDescrption'>
          <h3 className='DescriptionHeading'>Description</h3>
          <p className='infraDescrptionDetails'>{InfraSolution.solution_description}</p>
          <div className='infraDescrptionButtonColumn'>
            <div className='descriptionFormInfra'>
              <FormControl className={classes.formControl}>
                <InputLabel id='demo-controlled-open-select-label'>Platform</InputLabel>
                <Select
                  labelId='demo-controlled-open-select-label'
                  id='demo-controlled-open-select'
                  open={open}
                  onClose={handleClose}
                  onOpen={handleOpen}
                  value={platform}
                  onChange={handleChange}
                >
                  {dropdownContent}
                </Select>
              </FormControl>
            </div>
            <div className='downloadBtn'>
              <Button
                id='btn-1'
                variant='contained'
                color='primary'
                component='span'
                onClick={clickHandler}
                style={{
                  cursor: platform ? 'pointer' : 'not-allowed',
                  width: '100%',
                  padding: '10px',
                  backgroundColor: platform ? 'rgb(247, 80, 84)' : 'rgb(138, 116, 116)',
                  display: 'grid',
                  borderRadius: '10px',
                  border: 'None',
                }}
              >
                <span style={{ color: 'white', borderRadius: '10px' }}>Download</span>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfraDetails;
