import React, { useMemo } from 'react';
import axios from 'axios';
import SolutionInfraItem from '../Infrastructure/SolutionInfraItem';
import Loader from '../../components/Loader/BouncingDotsLoader';

import { useQuery } from '@tanstack/react-query';

async function fetchSolutionTemplateList() {
  try {
    const response = await axios.get('/api/v1/automation/solutions', {
      params: { solution_category: 'Containers' },
    });
    const data = await response.data;
    const templateList = data.map((template) => {
      const titleName = template.solution_name.toUpperCase();
      template.titleLogo = titleName[0] + titleName[1];
      const formatedTemplate = {
        titleLogo: template.titleLogo,
        id: template.id,
        title: template.solution_name,
        metric_value: template.metric_value,
        metric_name: template.metric_name,
        logos: template.logos,
        cloud_name: template.cloud_name,
        solution_category: template.solution_category,
      };
      return formatedTemplate;
    });
    return templateList;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export const Containerization = ({ searchTerm }) => {
  const { isError, isLoading, data } = useQuery({
    queryKey: ['containerization'],
    queryFn: fetchSolutionTemplateList,
  });

  const filteredData = useMemo(() => {
    if (!data) return [];
    return data.filter((item) => item.title.toLowerCase().includes(searchTerm.toLowerCase()));
  }, [searchTerm, data]);

  if (isLoading || isError) {
    return <Loader />;
  }

  return (
    <div className='infraParentContainer'>
      <span className='heading'>Containerization</span>
      <div className='infraItemsContainer'>
        {filteredData?.map((item) => (
          <SolutionInfraItem
            key={item.id}
            data={item}
            path={'containerization'}
          />
        ))}
      </div>
    </div>
  );
};
