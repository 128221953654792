import React, { useEffect, useState, useCallback } from 'react';
import { signInWithRedirect, fetchUserAttributes } from 'aws-amplify/auth';
import { useLocation } from 'react-router-dom';
import './Navbar.css';
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
/**
 * @author
 * @function Card
 **/
const useStyles = makeStyles((theme) => ({
  root: {
    paddingRight: theme.spacing(2),
  },
}));

var useremail = localStorage.getItem('userName');

const Navbar = (props) => {
  const { setquery, searchTerm } = props;

  const classes = useStyles();

  const [username, setusername] = useState('');

  const [name, setName] = useState('');
  const [profPic, setProfPic] = useState('');
  const [logo, setlogo] = useState('');

  const { pathname } = useLocation();

  const checkUser = useCallback(() => {
    fetchUserAttributes()
      .then((user) => {
        if (user.attributes) {
          setusername(user.attributes.email);
          let exceptDomain = user.attributes.email.substring(
            0,
            user.attributes.email.lastIndexOf('@'),
          );
          let currNameArr = exceptDomain.split('.');
          let capitalNameArr = currNameArr.map((word) => {
            return word[0].toUpperCase() + word.slice(1);
          });
          let currName = capitalNameArr.join(' ');
          setName(currName);
          setProfPic(user.attributes.picture);
          setlogo(currName.substring(0, 1));
        } else {
          setusername('');
        }
      })
      .catch((err) => console.error(err));

    if (!username) {
      if (useremail) {
        setusername(useremail);
        setName(useremail.substring(0, useremail.indexOf('@')));
        setlogo(useremail.substring(0, 1));
      }
    }
  }, [fetchUserAttributes]);

  useEffect(() => {
    checkUser();
  }, [checkUser]);

  useEffect(() => {
    setquery('');
  }, [pathname]);

  const Img = ({ success = profPic }) =>
    success ? (
      <img
        src={profPic}
        alt=''
        style={{ height: '45px', width: '45px', borderRadius: '50%' }}
      />
    ) : (
      <div className='ProfilePicSub'>{logo}</div>
    );

  let signedIn = (
    <div className='Loginuser'>
      <div className='ProfilePicMain'>
        <Img></Img>
      </div>
      <div style={{ width: '-webkit-fill-available', paddingLeft: '5px' }}>
        <div className='firstName'>{name}</div>
        <div className='userName'>{username}</div>
      </div>
    </div>
  );

  let signedOut = <div onClick={() => signInWithRedirect({ provider: 'Google' })}>Sign In</div>;

  let content = username ? signedIn : signedOut;

  const queryChanged = (event) => {
    setquery(event.target.value);
  };

  let searchBarContent = (
    <>
      <div className='inputSearch'>
        <i
          className='fa fa-search'
          aria-hidden='true'
          style={{
            color: 'rgb(135, 135, 135)',
            position: 'absolute',
            paddingLeft: '20px',
            paddingBottom: '10px',
            paddingTop: '10px',
          }}
        ></i>
        <input
          placeholder={
            pathname === '/pipelines'
              ? 'Search for specific pipeline'
              : pathname === '/Infrastructure'
                ? 'Search for Specific Infrastructure Solution'
                : pathname === '/Monitoring'
                  ? 'Search for Specific Monitoring Solution'
                  : pathname === '/security-and-compliance'
                    ? 'Search for Specific Security Solution'
                    : pathname === '/backup-and-dr'
                      ? 'Search for Specific Backup Solution'
                      : pathname === '/containerization'
                        ? 'Search for Specific Containerization Solution'
                        : ''
          }
          type='text'
          onChange={queryChanged}
          value={searchTerm}
          style={{
            width: '100%',
            paddingLeft: '43px',
            height: '-webkit-fill-available',
            fontFamily: 'Poppins',
            outline: 'transparent',
          }}
          className='navInput'
        />
      </div>
    </>
  );

  return (
    <div
      className='PipelineSearchBar'
      style={{
        width: useLocation().pathname.split('/').length === 2 ? '100%' : '100%',
      }}
    >
      <div>{useLocation().pathname.split('/').length === 2 ? searchBarContent : null}</div>
      <div className='NavbarModel'>
        <div className={classes.root}>
          <Button
            size='large'
            onClick={() => window.open(process.env.REACT_APP_SERVICE_URL, '_blank')}
          >
            Services
          </Button>
          <Button
            size='large'
            onClick={() => window.open(process.env.REACT_APP_SUPPORT_URL, '_blank')}
          >
            Support
          </Button>
        </div>
        <div className='LogedUserDiv'>{content}</div>
      </div>
    </div>
  );
};
export default Navbar;
