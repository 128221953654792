import React from 'react';
import { useState } from 'react';
import './style.css';
import HeaderImage from '../../../assets/Devops in a box.png';
import ModalVideo from 'react-modal-video';
import 'react-modal-video/scss/modal-video.scss';

/**
 * @author
 * @function SidebarLogo
 **/

const SidebarLogo = (props) => {
  const [isOpen, setOpen] = useState(false);

  return (
    <div className='sidebarLogoContainer'>
      <React.Fragment>
        <ModalVideo
          channel='youtube'
          autoplay
          isOpen={isOpen}
          videoId='nxU6aUPa8Qk'
          onClose={() => setOpen(false)}
        />
        <img
          className='headerImage'
          alt=''
          src={HeaderImage}
          onClick={() => setOpen(true)}
        />
        &nbsp;&nbsp;&nbsp;
      </React.Fragment>
    </div>
  );
};

export default SidebarLogo;
