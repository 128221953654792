import React from 'react';
import axios from 'axios';
import MonitoringItem from './MonitoringItem';
import './styles.css';
import { useQuery } from '@tanstack/react-query';

import Loader from '../../components/Loader/BouncingDotsLoader';
import { useMemo } from 'react';

const fetchMonitoringTemplateList = async () => {
  try {
    const response = await axios.get(`/api/v1/automation/solutions`, {
      params: { solution_category: 'Monitoring' },
    });
    const data = await response.data;
    const newInfraPipeline = data.map((pipe) => {
      let titleName = pipe.solution_name.toUpperCase();
      pipe.titleLogo = titleName[0] + titleName[1];
      let newPipe = {
        titleLogo: pipe.titleLogo,
        id: pipe.id,
        title: pipe.solution_name,
        metric_value: pipe.metric_value,
        metric_name: pipe.metric_name,
        logos: pipe.logos,
        cloud_name: pipe.cloud_name,
        solution_category: pipe.solution_category,
      };
      return newPipe;
    });

    return newInfraPipeline;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const Monitoring = ({ searchTerm }) => {
  const { isError, isLoading, data } = useQuery({
    queryKey: ['monitoring'],
    queryFn: fetchMonitoringTemplateList,
  });

  const filteredData = useMemo(() => {
    if (!data) return [];
    return data.filter((item) => item.title.toLowerCase().includes(searchTerm.toLowerCase()));
  }, [searchTerm, data]);

  if (isLoading || isError) {
    return <Loader />;
  }

  return (
    <div className='infraParentContainer'>
      <span className='heading'>Monitoring</span>
      <div className='infraItemsContainer'>
        {filteredData?.map((pipe) => {
          return (
            <MonitoringItem
              key={pipe.id}
              data={pipe}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Monitoring;
