import './App.css';
import { signInWithRedirect } from 'aws-amplify/auth';
import React, { useState } from 'react';
import Sidebar from './components/SideBar/index';
import NavBar from './components/Navbar/Navbar';
import Pipeline from '../src/views/Pipelines/index';
import PipelineDetails from './views/PipelineDetails/PipelineDetails';
import InfraDetails from './views/InfrastructureDetails/InfraDetails';
import Infrastructure from './views/Infrastructure';
import MonitoringDetails from './views/MonitoringDetails/MonitoringDetails';
import Monitoring from './views/Monitoring';
import { SecurityAndCompliance } from './views/SecurityAndCompliance/SecurityAndCompliance';
import { BackupAndDR } from './views/BackupAndDR/BackupAndDR';
import { Containerization } from './views/Containerization/Containerization';

import Footer from '../src/components/Footer/footer';
import Loader from './components/Loader/BouncingDotsLoader';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { useIsAuthenticated } from './hooks/useIsAuthenticated';

function App() {
  const [query, setQuery] = useState('');
  const { isAuthenticated, isLaoding } = useIsAuthenticated();

  if (isLaoding) {
    return <Loader />;
  }

  if (!isLaoding && !isAuthenticated) {
    signInWithRedirect();
    return;
  }

  return (
    <Router>
      <div className='App'>
        <Sidebar />
        <div className='container'>
          <NavBar
            searchTerm={query}
            setquery={setQuery}
          />
          <div className='appContainer'>
            <Routes>
              <Route
                path='/pipelines'
                element={
                  <Pipeline
                    searchTerm={query}
                    setquery={setQuery}
                  />
                }
              />
              <Route
                path='/pipelines/:id'
                element={<PipelineDetails />}
              />
              <Route
                path='/Infrastructure'
                element={
                  <Infrastructure
                    searchTerm={query}
                    setquery={setQuery}
                  />
                }
              />
              <Route
                path='/Infrastructure/:id'
                element={<InfraDetails />}
              />
              <Route
                path='/Monitoring'
                element={<Monitoring searchTerm={query} />}
              />
              <Route
                path='/Monitoring/:id'
                element={<MonitoringDetails />}
              />
              <Route
                path='/security-and-compliance'
                element={<SecurityAndCompliance searchTerm={query} />}
              />
              <Route
                path='/security-and-compliance/:id'
                element={<InfraDetails />}
              />
              <Route
                path='/backup-and-dr'
                element={<BackupAndDR searchTerm={query} />}
              />
              <Route
                path='/backup-and-dr/:id'
                element={<InfraDetails />}
              />
              <Route
                path='/containerization'
                element={<Containerization searchTerm={query} />}
              />
              <Route
                path='/containerization/:id'
                element={<InfraDetails />}
              />
              <Route
                path='/'
                element={<Navigate to='/pipelines' />}
              />
            </Routes>
          </div>
          <div style={{ width: '100%' }}>
            <Footer />
          </div>
        </div>
      </div>
    </Router>
  );
}
export default App;
