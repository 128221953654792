import React from 'react';

const InfiniteIcon = (props) => {
  return (
    <>
      <svg
        id='infinite'
        width='49.2'
        height='9.129'
        viewBox='0 0 20.997 9.129'
      >
        <path
          id='Path_11'
          data-name='Path 11'
          style={{ fill: props.color }}
          d='M16.611,9.132a4.215,4.215,0,0,1-2.569-.884.434.434,0,0,1-.126-.088L12,6.26a.457.457,0,1,1,.645-.647l1.837,1.824c.014.009.027.018.04.028a3.317,3.317,0,0,0,2.088.754,3.569,3.569,0,0,0,3.473-3.652A3.57,3.57,0,0,0,16.611.916a3.325,3.325,0,0,0-2.088.753.487.487,0,0,1-.068.046l-7.4,6.466a.441.441,0,0,1-.109.071,4.214,4.214,0,0,1-2.564.88A4.483,4.483,0,0,1,0,4.568,4.483,4.483,0,0,1,4.386,0,4.214,4.214,0,0,1,6.954.887.434.434,0,0,1,7.08.974l1.914,1.9a.457.457,0,0,1-.645.647L6.514,1.7l-.04-.028A3.321,3.321,0,0,0,4.386.916,3.57,3.57,0,0,0,.913,4.568,3.57,3.57,0,0,0,4.386,8.219a3.325,3.325,0,0,0,2.088-.753.5.5,0,0,1,.069-.046l7.4-6.466a.441.441,0,0,1,.109-.071A4.213,4.213,0,0,1,16.611,0,4.483,4.483,0,0,1,21,4.568,4.483,4.483,0,0,1,16.611,9.132Zm0,0'
          transform='translate(0 -0.003)'
        />
        <path
          id='Path_12'
          data-name='Path 12'
          style={{ fill: props.color }}
          d='M4.355,3.655H1.616a.457.457,0,0,1,0-.913H3.9V.46a.456.456,0,1,1,.913,0V3.2A.457.457,0,0,1,4.355,3.655Zm0,0'
          transform='translate(4.318 -0.003)'
        />
        <path
          id='Path_13'
          data-name='Path 13'
          style={{ fill: props.color }}
          d='M2.969,4.814a.457.457,0,0,1-.457-.457V1.619a.457.457,0,0,1,.457-.457H5.707a.457.457,0,0,1,0,.913H3.425V4.358A.457.457,0,0,1,2.969,4.814Zm0,0'
          transform='translate(9.356 4.315)'
        />
      </svg>
    </>
  );
};

export default InfiniteIcon;
