import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import { Amplify } from 'aws-amplify';
import { signOut, fetchAuthSession } from 'aws-amplify/auth';
import axios from 'axios';
import { ThemeProvider } from '@mui/styles';
import { createTheme } from '@mui/material';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

// TODO: Migrate from CRA to Vite

Amplify.configure({
  Auth: {
    Cognito: {
      region: process.env.REACT_APP_AWS_REGION,
      userPoolId: process.env.REACT_APP_USER_POOL_ID,
      userPoolClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
      loginWith: {
        oauth: {
          domain: process.env.REACT_APP_OAUTH_DOMAIN,
          scopes: ['openid', 'email', 'phone', 'profile', 'aws.cognito.signin.user.admin'],
          redirectSignIn: ['http://localhost:3000/', process.env.REACT_APP_REDIRECT_URL],
          redirectSignOut: ['http://localhost:3000/', process.env.REACT_APP_REDIRECT_URL],
          responseType: 'code',
        },
        username: true,
        email: true,
      },
    },
  },
});

axios.interceptors.request.use(function (config) {
  config.baseURL = process.env.REACT_APP_API_BASE_URL;
  return new Promise((resolve, reject) => {
    fetchAuthSession()
      .then((session) => {
        const token = session.tokens.idToken.toString();
        config.headers.Authorization = 'Bearer ' + token;
        resolve(config);
      })
      .catch(() => {
        // No logged-in user: don't set auth header
        resolve(config);
        signOut();
      });
  });
});

const theme = createTheme();
const queryClinet = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      staleTime: 5 * 60 * 100, // 5 Minutes in ms
    },
  },
});

const root = createRoot(document.getElementById('root'));

root.render(
  <React.Fragment>
    <QueryClientProvider client={queryClinet}>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </QueryClientProvider>
  </React.Fragment>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
