import React, { useState, useEffect } from 'react';
import axios from 'axios';
import SolutionInfraItem from './SolutionInfraItem';
import './styles.css';

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Loader from '../../components/Loader/BouncingDotsLoader';

/**
 * @author
 * @function InfraSolution
 **/

const cloudNameOptions = [
  { value: '1', label: 'AWS' },
  { value: 2, label: 'Azure' },
  { value: 3, label: 'Google Cloud' },
];

const infrastructureCategoryOptions = [
  { value: 1, label: 'Database' },
  { value: '2', label: 'Storage' },
  { value: 3, label: 'Network' },
  { value: 4, label: 'Compute' },
  { value: 5, label: 'Security' },
  { value: 6, label: 'Containers' },
];

const Infrastructure = (props) => {
  const [solution, setSolution] = useState([]);
  const [queryInfra, setQueryInfra] = useState([]);
  const [loading, setLoading] = useState(true);
  const [category, setCategory] = useState([]);
  const [cloudNameDisabled, setCloudNameDisabled] = useState(true);
  const [cloudNameValue, setCloudNameValue] = useState([]);
  const [newQueryInfra, setNewQueryInfra] = useState([]);

  function getSolutionData() {
    axios
      .get(`/api/v1/automation/solutions?solution_category=All`)
      .then((res) => {
        setLoading(false);
        let infraData = res.data;
        let newInfraPipeline = infraData.map((pipe) => {
          let titleName = pipe.solution_name.toUpperCase();
          pipe.titleLogo = titleName[0] + titleName[1];
          let newPipe = {
            titleLogo: pipe.titleLogo,
            id: pipe.id,
            title: pipe.solution_name,
            metric_value: pipe.metric_value,
            metric_name: pipe.metric_name,
            logos: pipe.logos,
            cloud_name: pipe.cloud_name,
            solution_category: pipe.solution_category,
          };
          return newPipe;
        });

        setSolution(newInfraPipeline);
        setQueryInfra(newInfraPipeline);
        setCategory(newInfraPipeline);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  useEffect(() => {
    clearCloudName();
    setLoading(true);
    getSolutionData();
    return;
  }, []);

  const clearCloudName = () => {
    var v = cloudNameOptions.find((v) => v.label[-1]);
    setCloudNameValue(v);
  };

  const handleCloudNameDropDown = (value) => {
    if (value) {
      setCloudNameValue(value);
      const current_cloud_name = value.label;
      const filterDropdownn = category.filter(function (data) {
        return data.cloud_name === current_cloud_name;
      });
      setQueryInfra(filterDropdownn);
    } else {
      setQueryInfra(category);
      clearCloudName();
    }
  };

  const handleCategoryDropDown = (value) => {
    clearCloudName();
    if (value) {
      const current_category_name = value.label;
      const filterDropdown = solution.filter(function (data) {
        return data.solution_category === current_category_name;
      });
      setQueryInfra(filterDropdown);
      setCategory(filterDropdown);
      setCloudNameDisabled(false);
    } else {
      setCloudNameDisabled(true);
      return getSolutionData();
    }
  };

  useEffect(() => {
    let re = new RegExp('.*?');
    if (props.searchTerm) {
      let searchText = props.searchTerm;
      let escapeSpecialChar = /[;\\/:*?\"<>|&'\[\]+()]/g;
      searchText = searchText.replaceAll(escapeSpecialChar);
      re = new RegExp(searchText, 'i');
    }
    setQueryInfra(solution.filter((pipe) => pipe.title.search(re) !== -1));
  }, [props.searchTerm]);
  const setTitle = () => {
    if (queryInfra.length && loading === false) {
      return ' All Results';
    } else if (loading === true) {
      return <Loader />;
    } else {
      return 'No Results Found';
    }
  };
  var updatedqueryInfra = queryInfra.filter(
    (item) => item.title.indexOf('Amazon Cloudwatch') === -1,
  );
  return (
    <>
      <div className='dropdown'>
        <div>
          <Autocomplete
            style={{
              marginTop: '12px',
              marginRight: '10px',
              backgroundColor: '#fff',
              borderRadius: '18px',
            }}
            id='categoryNameDropdownId'
            onChange={(event, value) => handleCategoryDropDown(value)}
            options={infrastructureCategoryOptions}
            getOptionLabel={(option) => option.label}
            sx={{ width: 160 }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder='Category Name'
                size='small'
              />
            )}
          />
        </div>

        <div>
          <Autocomplete
            style={{
              marginTop: '12px',
              marginRight: '10px',
              backgroundColor: '#fff',
              borderRadius: '18px',
            }}
            id='cloudNameDropdownId'
            onChange={(event, value) => handleCloudNameDropDown(value)}
            value={cloudNameValue}
            options={cloudNameOptions}
            getOptionLabel={(options) => options.label || ''}
            disabled={cloudNameDisabled}
            sx={{ width: 160 }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder='Cloud Name'
                size='small'
              />
            )}
          />
        </div>
      </div>

      <div className='infraParentContainer'>
        <span className='heading'>{setTitle()}</span>

        <div className='infraItemsContainer'>
          {updatedqueryInfra.map((pipe) => {
            return (
              <SolutionInfraItem
                key={pipe.id}
                data={pipe}
                path={'Infrastructure'}
              />
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Infrastructure;
